import React, { useState, useEffect, useContext } from "react";
import { LangContext } from "../../context/LangContext";
import { Link } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Animation from "../../utils/Animation";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import newsIcon from "../../assets/img/newsIcon.png";
// import grievOfficer from '../../assets/img/grievOfficer2.jpg';
import { AiOutlineMail, AiOutlineMobile, AiOutlinePhone } from "react-icons/ai";
import { BsTelephoneOutbound } from "react-icons/bs";
// import defaultProfile from '../../assets/img/defaultProfile.png';
import digitalBG from "../../assets/img/digitalPayments02.jpg";
import gImage from "../../assets/img/Photo_Umesh.jpg";
import "./accordion.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "10px",
  "&:before": {
    display: "none",
  },
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
  marginBottom: "4px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "rgba(102, 51, 102, 1)" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(229, 228, 226, 1)",
  // 'rgba(229, 228, 226, 1)',
  // 'rgba(162, 69, 109, 1)',
  // 'linear-gradient(to bottom, #e636c2, #d35da4)'
  // 'rgba(162, 69, 109, 1)',
  // 'rgba(162, 69, 109, 1)
  color: "rgba(102, 51, 102, 1)",
  // 'rgba(190, 84, 142, 1)',
  // 'rgba(169, 58, 124, 1)'
  borderRadius: "10px",
  flexDirection: "row",
  minHeight: "20px",
  maxHeight: "42px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(255, 255, 255, 1)",
}));

export default function CustomizedAccordions({
  news,
  audio,
  grievance,
  information,
  compliance,
  secretary,
}) {
  // console.log("grievance", grievance);
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const [expanded, setExpanded] = React.useState();

  // console.log("expanded =>", typeof expanded, expanded)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [isVisible, setIsVisible] = useState(true); // State to track visibility
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible); // Toggle visibility
    }, 5000);
    // return () => clearInterval(intervalId);
  }, []);
  return (
    <div ref={ref} animate={controls} initial="hidden" variants={Animation}>
      <Accordion
        className="singleAccordion"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {np ? "समाचार र सूचनाहरू" : "News & Notices"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="landingAccordionListContainer">
            {news?.map((value) => (
              <Link key={value.id} to={`/news-and-notices/${value.id}`}>
                <li className="landingAccordionListItem">
                  <img className="listImage" src={newsIcon} alt="newsIcon" />
                  {np
                    ? value.TitleNepali.length === 0
                      ? value.Title
                      : value.TitleNepali
                    : value.Title}
                </li>
              </Link>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>

      {/* audio Nodice start */}
      <Accordion
        className="singleAccordion"
        expanded={expanded === "AudioNotice"}
        onChange={handleChange("AudioNotice")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {np ? "अडियो सूचनाहरू" : "Audio Notices"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="landingAccordionListContainer ">
            {audio?.map((value) => (
              <li className="landingAccordionListItem Audio">
                <h5>{value.Title}</h5>
                <AudioPlayer
                  // autoPlay
                  src={value.DocPath}
                  onPlay={(e) => console.log("onPlay")}
                />
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="singleAccordion"
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {np ? "सूचना अधिकारी" : "Chief Information Officer"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="landingAccordionOfficerContainer">
            <LazyLoadImage
              effect="blur"
              className="landingAccordionImage"
              src={information?.featured_image}
              alt={information?.slug}
            />
            <div className="landingAccordionDescContainer">
              <p className="landingAccordionName">
                {np
                  ? information?.name_nepali?.length === 0
                    ? information?.name
                    : information?.name_nepali
                  : information?.name}
              </p>

              <div className="landingAccordionEmailFlex">
                <AiOutlineMail style={{ fontSize: "18px", color: "#663366" }} />
                <p className="landingAccordionEmail">{information?.email}</p>
              </div>

              {/* <div className='landingAccordionEmailFlex'>
                  <AiOutlineMobile style={{ fontSize:'18px', color: "#663366" }} />
                  <p className='landingAccordionMobile'>9851091602</p>
                  </div> */}

              <div className="landingAccordionEmailFlex">
                <AiOutlinePhone
                  style={{ fontSize: "18px", color: "#663366" }}
                />
                <p className="landingAccordionPhone">{information?.contact}</p>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="singleAccordion"
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {np ? "गुनासो अधिकारी" : "Grievance Officer"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className={`landingAccordionOfficerContainer primary ${
              isVisible ? "" : "hidden"
            }`}
          >
            <LazyLoadImage
              effect="blur"
              className="landingAccordionImage"
              src={gImage}
              alt={Image}
            />
            <div className="landingAccordionDescContainer">
              <p className="landingAccordionName">
                {np ? "उमेश थापा" : "Mr. Umesh Thapa"}
              </p>
              <p className="landingAccordionEmailFlex">
                {np ? "वरिष्ठ अधिकारी" : "Senior Officer"}
              </p>

              <div className="landingAccordionEmailFlex">
                <AiOutlineMail style={{ fontSize: "18px", color: "#663366" }} />
                <p className="landingAccordionEmail">umesh.thapa@pcbl.com.np</p>
              </div>

              <div className="landingAccordionEmailFlex">
                <AiOutlineMobile
                  style={{ fontSize: "18px", color: "#663366" }}
                />
                <p className="landingAccordionMobile">9841600276</p>
              </div>

              <div className="landingAccordionEmailFlex">
                <AiOutlinePhone
                  style={{ fontSize: "18px", color: "#663366" }}
                />
                <p className="landingAccordionPhone">01-4423215 ext: 2157</p>
              </div>
            </div>
          </div>
          <div
            className={`landingAccordionOfficerContainer secoundry ${
              isVisible ? "hidden" : ""
            }`}
          >
            <LazyLoadImage
              effect="blur"
              className="landingAccordionImage"
              src={grievance?.featured_image}
              alt={grievance?.slug}
            />
            <div className="landingAccordionDescContainer">
              <p className="landingAccordionName">
                {np
                  ? grievance?.name_nepali?.length === 0
                    ? grievance?.name
                    : grievance?.name_nepali
                  : grievance?.name}
              </p>
              <p className="landingAccordionEmailFlex">
                {np
                  ? grievance?.sub_designation_nepali?.length === 0
                    ? grievance?.sub_designation
                    : grievance?.sub_designation_nepali
                  : grievance?.sub_designation}
              </p>

              <div className="landingAccordionEmailFlex">
                <AiOutlineMail style={{ fontSize: "18px", color: "#663366" }} />
                <p className="landingAccordionEmail">{grievance?.email}</p>
              </div>

              <div className="landingAccordionEmailFlex">
                <AiOutlineMobile
                  style={{ fontSize: "18px", color: "#663366" }}
                />
                <p className="landingAccordionMobile">{grievance?.contact}</p>
              </div>

              <div className="landingAccordionEmailFlex">
                <AiOutlinePhone
                  style={{ fontSize: "18px", color: "#663366" }}
                />
                <p className="landingAccordionPhone">01-4423215 ext: 2165</p>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="singleAccordion"
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {np ? "अनुपालन अधिकारी" : "Compliance Officer"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="landingAccordionOfficerContainer">
            <LazyLoadImage
              effect="blur"
              className="landingAccordionImage"
              src={compliance?.featured_image}
              alt={compliance?.slug}
            />
            <div className="landingAccordionDescContainer">
              <p className="landingAccordionName">
                {np
                  ? compliance?.name_nepali?.length === 0
                    ? compliance?.name
                    : compliance?.name_nepali
                  : compliance?.name}
              </p>

              <div className="landingAccordionEmailFlex">
                <AiOutlineMail style={{ fontSize: "18px", color: "#663366" }} />
                <p className="landingAccordionEmail">{compliance.email}</p>
              </div>

              {/* <div className='landingAccordionEmailFlex'>
                  <AiOutlineMobile style={{ fontSize:'18px', color: "#663366" }} />
                  <p className='landingAccordionMobile'>{grievance?.contact}</p>
                  </div> */}

              <div className="landingAccordionEmailFlex">
                <AiOutlinePhone
                  style={{ fontSize: "18px", color: "#663366" }}
                />
                <p className="landingAccordionPhone">{compliance.contact}</p>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="singleAccordion"
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {np ? "कम्पनी सचिव" : "Company Secretary"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="landingAccordionOfficerContainer">
            <LazyLoadImage
              effect="blur"
              className="landingAccordionImage"
              src={secretary?.featured_image}
              alt={secretary?.slug}
            />
            <div className="landingAccordionDescContainer">
              <p className="landingAccordionName">
                {np
                  ? secretary?.name_nepali?.length === 0
                    ? secretary?.name
                    : secretary?.name_nepali
                  : secretary?.name}
              </p>

              <div className="landingAccordionEmailFlex">
                <AiOutlineMail style={{ fontSize: "18px", color: "#663366" }} />
                <p className="landingAccordionEmail">{secretary.email}</p>
              </div>

              {/* <div className='landingAccordionEmailFlex'>
                  <AiOutlineMobile style={{ fontSize:'18px', color: "#663366" }} />
                  <p className='landingAccordionMobile'>{grievance?.contact}</p>
                  </div> */}

              <div className="landingAccordionEmailFlex">
                <AiOutlinePhone
                  style={{ fontSize: "18px", color: "#663366" }}
                />
                <p className="landingAccordionPhone">{secretary.contact}</p>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* linear-gradient(to right, rgb(248, 7, 89, 0.7), rgb(188, 78, 156, 0.7)) */}
      {!expanded && (
        <div
          className="digitalBankingAccordionContainer"
          style={{
            background: `linear-gradient(rgb(169, 58, 124,0.7), rgb(169, 58, 124,0.7)), url(${digitalBG})`,
          }}
        >
          <h3
            style={{
              color: "#fff",
              fontSize: "18px",
              color: "#fff",
              marginBottom: "1px",
            }}
            className="GetInTouchPageContentSubHeading"
          >
            {np ? "डिजिटल बैंकिङ सोधपुछ" : "Digital Banking Inquiries"}
          </h3>
          <p
            style={{ color: "#fff", marginBottom: "5px" }}
            className="GetInTouchPageContentSubSubHeadingATM"
          >
            (ATM , POS,QR, Mobile Banking)
          </p>
          <h4
            style={{ color: "#fff" }}
            className="GetInTouchPageContentInfoTextAcc"
          >
            <AiOutlineMail style={{ fontSize: "18px", color: "#fff" }} />
            card.prime@pcbl.com.np
          </h4>
          <h4
            style={{ color: "#fff" }}
            className="GetInTouchPageContentInfoTextAcc"
          >
            <BsTelephoneOutbound style={{ fontSize: "18px", color: "#fff" }} />
            {np ? "०१-५९०१४६२" : "01-5901462"}
          </h4>
        </div>
      )}
    </div>
  );
}
